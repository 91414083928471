@use "../../assets/styles/mixins.scss" as *;

.helpContent {
  background-color: #d9f6f9;
  padding: 40px;

  @include breakpoint-up(lg) {
    padding: 20px 40px;
  }
}

.helpWrapper {
  max-width: 1100px;
  margin: auto;
  @include breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.helpTexts {
  h1 {
    margin-bottom: 0;
    font-size: 36px;
  }

  p {
    margin-top: 10px;
    font-size: 18px;
  }
}
