@use "../../assets/styles/mixins.scss" as *;

.bannerContent {
  padding: 0;

  .bannerContainer {
    margin: 0;
    width: 100%;

    @include breakpoint-up(md) {
      flex-direction: row-reverse;
    }
  }
}

.bannerImage {
  height: 250px;

  @include breakpoint-up(md) {
    height: unset;
  }
}

.bannerText {
  padding-left: 20px;
  padding-right: 20px;

  @include breakpoint-up(md) {
    padding: 30px 50px;
  }

  h1 {
    font-size: 44px;
    line-height: 52px;
    font-weight: 900;
    margin-bottom: 12px;
  }

  p {
    margin-top: 0;
    font-size: 18px;

    @include breakpoint-up(lg) {
      font-size: 20px;
    }
  }

  h2 {
    @include breakpoint-up(lg) {
      font-size: 32px;
    }
  }
}
