import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({}) => (
  <div className={style.footerContent} role="contentinfo">
    <div className={style.footerWrapper}>
      <p>
        If you or someone you know is in crisis, seek safety and get help right
        away.{" "}
        <b>
          If you or someone you know is in immediate danger, call 911 or go to
          the closest emergency room.
        </b>
      </p>
      <p>
        <b>
          To reach a trained crisis counselor, call the 988 Suicide & Crisis
          Lifeline (previously known as the National Suicide Prevention
          Lifeline) as 988 or 1-800-273-TALK (1800-273-8255).
        </b>{" "}
        You can also text <b>988</b> or chat at <b>988lifeline.org.</b> The
        lifeline provides 24/7 free and confidential support.
      </p>
      <p>
        Optum is a registered trademark of Optum, Inc. in the U.S. and other
        jurisdictions. All other brand or product names are the property of
        their respective owners. Because we are continuously improving our
        products and services. Optum reserves the right to change specifications
        without prior notice. Optum is an equal opportunity employer.
      </p>
      <p>© 2023 Optum, Inc. All rights reserved.</p>
    </div>
  </div>
);
