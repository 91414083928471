import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({}) => (
  <div
    className={style.servicesContent}
    role="region"
    aria-labelledby="supportService"
  >
    <h2 className={style.title} id="supportService">
      Getting support for yourself or loved ones is easy with Optum emotional
      wellbeing services.
    </h2>
    <p>
      Go to{" "}
      <a
        blank="_target"
        href="https://www.liveandworkwell.com/?pin=hca&redirectURL=/content/en/member.html"
        aria-label="Go to liveandworkwell.com"
      >
        <strong>liveandworkwell.com</strong>
      </a>{" "}
      to learn more about:
    </p>
    <div className={style.servicesItemContainer}>
      <div className="row">
        <div
          className={cn([
            style.servicesItemWrapper,
            "col-sm-12 col-md-6 col-lg-4",
          ])}
        >
          <div className={style.servicesItem}>
            <div className={style.item}>
              <img
                alt="Conversation Icon"
                src="/assets/Icon_Conversation_RGB.svg"
                width={80}
                height={80}
              />
              <h2>8 counseling visits per topic, per year</h2>
              <p>in-network at no cost to you</p>
            </div>
          </div>
        </div>
        <div
          className={cn([
            style.servicesItemWrapper,
            "col-sm-12 col-md-6 col-lg-4",
          ])}
        >
          <div className={style.servicesItem}>
            <div className={cn([style.item, style.star])}>
              <img
                alt="Star Icon"
                src="/assets/Icon_Star_RGB.svg"
                width={80}
                height={80}
              />
              <h2>In-the-moment support</h2>
              <p>from a master's-level clinician</p>
            </div>
          </div>
        </div>
        <div
          className={cn([
            style.servicesItemWrapper,
            "col-sm-12 col-md-12 col-lg-4",
          ])}
        >
          <div className={cn(style.servicesItem)}>
            <div className={cn([style.item, style.virtualCare])}>
              <img
                alt="Virtual Care Icon"
                src="/assets/Icon_VirtualCare_RGB.svg"
                width={80}
                height={80}
              />
              <h2>24/7 virtual visits</h2>
              <p>with a dedicated licensed therapist via the Talkspace app</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
