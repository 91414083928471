.appContainer {
  background-color: #fff;
  display: flex;
  justify-content: center;
  cursor: default;
}

.app {
  width: 100%;
  max-width: 1440px;
  position: relative;
  box-shadow: 1px 0 #d0d0ce, -1px 0 #d0d0ce;
}
