@use "../../assets/styles/mixins.scss" as *;

.servicesContent {
  background-color: #faf8f2;
  padding: 40px 20px;
  text-align: center;

  p {
    font-size: 18px;
  }

  a {
    color: #186dcf;
  }
}

.title {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint-up(md) {
    font-size: 2em;
  }
}

.servicesItemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.servicesItemContainer {
  max-width: 1100px;
  margin: 50px auto 0 auto;
}

.servicesItem {
  background-color: #ffff;
  margin: 15px;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    margin-top: 12px;
  }
}

.item {
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.star {
  margin-top: 25px;
}

.virtualCare {
  margin-top: 16px;
}
