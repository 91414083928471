import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default () => (
  <div
    className={cn(style.directorQuoteContent)}
    role="region"
    aria-labelledby="directorBenhalimQuote"
  >
    <div className={cn(style.directorQuoteWrapper)} id="directorBenhalimQuote">
      <div className="row">
        <div className="col-sm-12 col-md-4">
          <div className={cn(style.directorImage)}>
            <img
              alt="Doctor Yusra Benhalim"
              src="/assets/Dr_Benhalim.webp"
              width={110}
              height={110}
            />
          </div>
          <div className={cn(style.directorInfo)}>
            <span className={cn(style.directorName)}>Dr. Yusra Benhalim</span>
            <span className={cn(style.directorPos)}>
              Senior Medical Director,
            </span>
            <span className={cn(style.directorPos)}>
              Optum Behavioral Health
            </span>
          </div>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className={cn(style.directorQuote)}>
            <div className={cn(style.quoteImage)}>
              <img
                alt="Doctor Yusra Benhalim Quote"
                src="/assets/quote.webp"
                width={50}
                height={50}
              />
            </div>
            <div className={cn(style.quoteContent)}>
              <div className={cn(style.quoteText)}>
                "We think about the ways in which we can get the right
                information out ... ways that we can help families know more
                about the right solutions and be able to make the best-informed
                decision because they really are the experts on their child and
                their family."
              </div>
              <p className={cn(style.quoteLink)}>
                <a
                  target="_blank"
                  href="/assets/optum-youth-mental-health-article.b3afd2d4.pdf"
                  aria-label="Read Doctor Benhalim's article for more insights"
                >
                  Read Dr. Benhalim's article for more insights {`>`}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
