@use "../../assets/styles/mixins.scss" as *;

.footerContent {
  background-color: #ffff;
  padding: 20px 40px;
}

.footerWrapper {
  max-width: 1100px;
  margin: auto;
  color: #4b4d4f;
  font-size: 14px;

  p {
    margin: 20px 0;
  }
}
