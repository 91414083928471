@use "../../assets/styles/mixins.scss" as *;

.headerContent {
  min-height: 72px;
  background-color: #faf8f2;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 30px;

  @include breakpoint-up(md) {
    padding: 30px 50px;
  }
}

.optumContainer {
  padding-right: 25px;
  border-right: solid 1px lightgray;
  justify-content: center;
  display: flex;
}

.logoContainer {
  padding-left: 25px;
}

.optumContainer {
  img {
    height: 30px;
    width: 100px;

    @include breakpoint-up(md) {
      height: 40px;
      width: 137px;
    }
  }
}

.logoContainer {
  img {
    height: 30px;
    width: 75px;

    @include breakpoint-up(md) {
      height: 40px;
      width: 93px;
    }
  }
}
