@use "../../assets/styles/mixins.scss" as *;

.directorQuoteContent {
  background-color: #ffff;
  padding: 50px 20px;
}

.directorQuoteWrapper {
  max-width: 1025px;
  margin: auto;
}

.directorImage,
.directorInfo,
.quoteImage {
  display: flex;
  align-content: center;
  justify-content: center;
}

.directorInfo {
  flex-direction: column;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.directorName {
  color: #002577;
  font-weight: 700;
  padding-bottom: 5px;
}

.directorPos {
  color: #5a5a5a;
  font-size: 14px;
}

.directorQuote {
  margin-top: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  @include breakpoint-up(md) {
    margin-top: 0;
  }
}

.quoteImage {
  margin-right: 20px;
}

.quoteContent {
  text-align: left;
  @include breakpoint-up(md) {
    font-size: 18px;
  }
}

.quoteLink {
  color: #1c70cf;
  font-weight: 700;
}
