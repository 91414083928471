import React from "react";
import cn from "classnames";

import Button from "../Button";

import style from "./style.scss";

export default ({}) => (
  <div
    className={style.supportContent}
    role="region"
    aria-labelledby="supportParents"
  >
    <div className={style.supportTitle} id="supportParents">
      <span className={style.supportTitleText}>
        Support for parents and caregivers
      </span>
    </div>
    <div className={cn(style.supportWrapper)}>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className={cn(style.supportItem)}>
            <div
              className={cn(style.supportImage)}
              style={{
                backgroundImage: `url(/assets/image_talking-with-teens.webp)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div className={cn(style.supportItemWrapper)}>
              <h1>Talking with teens about mental health</h1>
              <p>Get four tips for starting the conversation.</p>
              <Button
                text="Read now"
                onClick={() => {
                  window.open(
                    "/assets/Talking_about_mental_health_4_tips_for_parents_of_teens_and_young_adults_en-US.6209d73c.pdf",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className={cn(style.supportItem)}>
            <div
              className={cn(style.supportImage)}
              style={{
                backgroundImage: `url(/assets/image_start-conversation.webp)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div className={cn(style.supportItemWrapper)}>
              <h1>Start the conversation</h1>
              <p>
                Not sure how to get your teen talking or move the conversation
                past one-word answers? Try this digital deck of 30 conversations
                starters.
              </p>
              <Button
                text="Download for desktop"
                buttonStyle={style.supportButton}
                onClick={() => {
                  window.open(
                    "/assets/Optum_EAP_ConversationStarters_Desktop.1e416ca7.pdf",
                    "_blank"
                  );
                }}
              />
              <Button
                text="Download for mobile"
                buttonStyle={style.supportButton}
                onClick={() => {
                  window.open(
                    "/assets/Optum_EAP_ConversationStarters_Mobile.80e58225.pdf",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
