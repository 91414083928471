@use "../../assets/styles/mixins.scss" as *;

.supportContent {
  background-color: #d9f6f9;
  padding: 60px 20px;
}

.supportTitle {
  text-align: center;
  padding-bottom: 15px;
}

.supportTitleText {
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 15px;
  border-bottom: 1px solid;
  line-height: 60px;
}

.supportItem {
  padding-top: 50px;
}

.supportImage {
  height: 315px;
}

.supportItemWrapper {
  h1 {
    margin-bottom: 12px;
    font-size: 28px;
    font-weight: 900;

    @include breakpoint-up(lg) {
      font-size: 44px;
      line-height: 52px;
      margin-right: 30px;
    }
  }

  p {
    margin-top: 0;
    @include breakpoint-up(lg) {
      font-size: 18px;
    }
  }
}

.supportButton {
  margin-top: 10px;
  margin-right: 10px;
}

.supportWrapper {
  max-width: 1100px;
  margin: auto;
}
