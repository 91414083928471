import React from "react";
import cn from "classnames";

import Button from "../Button";

import style from "./style.scss";

export default ({}) => (
  <div className={style.helpContent} role="region" aria-labelledby="moreHelp">
    <div className={style.helpWrapper} id="moreHelp">
      <div className={style.helpTexts}>
        <h1>Looking for more information and help?</h1>
        <p>Review additional benefits available to you.</p>
      </div>
      <div className={style.helpCta}>
        <Button
          text="Learn more"
          onClick={() => {
            window.open(
              "https://www.liveandworkwell.com/?pin=hca&redirectURL=/content/en/member.html",
              "_blank"
            );
          }}
        />
      </div>
    </div>
  </div>
);
