import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({}) => (
  <div className={cn(style.headerContent)} role="banner">
    <div className={cn(style.optumContainer)}>
      <img
        alt="Optum Logo"
        src="/assets/Optum_Logo_ora_RGB.webp"
        height={40}
        width={137}
      />
    </div>
    <div className={cn(style.logoContainer)}>
      <img
        alt="HCA HealthCare Logo"
        src="/assets/HCA_LogoSM_RGB_Stacked.webp"
        height={40}
        width={93}
      />
    </div>
  </div>
);
