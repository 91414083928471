import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({}) => (
  <div
    className={style.bannerContent}
    role="region"
    aria-labelledby="supportBanner"
  >
    <div id="supportBanner" className={cn(["row", style.bannerContainer])}>
      <div
        className={cn([style.bannerImage, "col-sm-12 col-md-5 col-lg-6"])}
        style={{
          backgroundImage: `url(/assets/Acr21663217179648545125.webp)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="col-sm-12 col-md-7 col-lg-6">
        <div className={cn(style.bannerText)}>
          <h1>
            Being a teen in today's world is tough — on teens and those who love
            them.
          </h1>
          <p>Get support for the difficult moments and tough conversations.</p>
          <h2>
            Call{" "}
            <a href="tel:1-877-950-5075" aria-label="Call 18779505075">
              1-877-950-5075.
            </a>
          </h2>
        </div>
      </div>
    </div>
  </div>
);
