import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({}) => (
  <div
    className={style.supportTeensContent}
    role="region"
    aria-labelledby="supportTeens"
  >
    <div className={style.supportTeensWrapper} id="supportTeens">
      <div className={style.supportTitle}>
        <span className={style.supportTitleText}>Support for teens</span>
      </div>
      <div className={cn(style.supportWrapper)}>
        <div className="row">
          <div className="col-sm-12 col-md-5">
            <div className={style.selfCareImageWrapper}>
              <div
                className={style.selfCareImage}
                style={{
                  backgroundImage: `url(/assets/Acr21663217179648-1500723.webp)`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </div>
          <div className={cn(["col-sm-12 col-md-7", style.selfCareWrapper])}>
            <div className={style.selfCareText}>
              <h1>Self Care from AbleTo</h1>
              <p>
                Explore mental health at your own pace with Self Care from
                AbleTo. The app gives you self-care techniques, coping tools,
                meditations and more.
              </p>
              <h2 className={cn(style.useCode)}>
                Get the app. Use code <i>teen</i>.
              </h2>
              <div className={style.storeContainer}>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.joyable.joyableApp&pcampaignid=web_share"
                  aria-label="Go to Google Play"
                >
                  <img
                    alt="Google Play"
                    src="/assets/btn_GooglePlay.webp"
                    height={40}
                    width={139}
                  />
                </a>
                <a
                  target="_blank"
                  href="https://apps.apple.com/us/app/ableto/id1065052030"
                  aria-label="Go to App Store"
                >
                  <img
                    alt="Apple Store"
                    src="/assets/btn_AppStore.webp"
                    height={40}
                    width={139}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(style.boxWrapper)}>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className={cn(style.boxItem)}>
              <img
                alt="Wellness Icon"
                src="/assets/Icon_Wellness_RGB.svg"
                width={80}
                height={80}
              />
              <h3>Soothing activities to relax, reset or clear your mind</h3>
              <a
                target="_blank"
                href="/assets/August_2023_Clear_Your_Mind_List_en-US.pdf"
                aria-label="See activities"
              >
                <p className={cn(style.link)}>See activities {`>`}</p>
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className={cn(style.boxItem)}>
              <img
                alt="Mobile Icon"
                src="/assets/Icon_MobilePhone_RGB.svg"
                width={80}
                height={80}
              />
              <h3>How does your social media use affect you?</h3>
              <p className={cn(style.link)}>
                <a
                  alt="Quiz Icon"
                  target="_blank"
                  href="/assets/August_2023_Social_Media_Quiz_en-US.pdf"
                  aria-label="Take the quiz"
                >
                  Take the quiz {`>`}
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className={cn(style.boxItem)}>
              <img
                alt="Time Repitition Icon"
                src="/assets/Icon_Time_Repetition_RGB.svg"
                width={80}
                height={80}
              />
              <h3>Creating a routine to benefit your mental health</h3>
              <a
                target="_blank"
                href="https://optumwellbeing.com/sec_TrendingTopics/cnt_art_O2312_Creatingaroutinetobenefityourmentalhealth/en-US"
                aria-label="Read creating a routine to benefit your mental health"
              >
                <p className={cn(style.link)}>Read now {`>`}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
