import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({ text, onClick, buttonStyle }) => (
  <button className={cn([style.button, buttonStyle])} onClick={onClick}>
    {text}
  </button>
);
