@use "../../assets/styles/mixins.scss" as *;

.supportTeensContent {
  background-color: #f9f8f3;
  padding: 60px 20px;

  .selfCareWrapper {
    margin: auto;
  }
}

.supportTitle {
  text-align: center;
  padding-bottom: 15px;
}

.supportTitleText {
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 15px;
  border-bottom: 1px solid;
  line-height: 60px;
}

.supportWrapper {
  max-width: 845px;
  margin-top: 50px;
  @include breakpoint-up(md) {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }
}

.selfCareImageWrapper {
  margin-bottom: 30px;
}

.useCode {
  font-size: 22px;
}

.selfCareImage {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 auto;
}

.selfCareText {
  text-align: center;
  @include breakpoint-up(md) {
    text-align: left;
  }
  @include breakpoint-up(md) {
    padding-left: 30px;
  }

  h1 {
    @include breakpoint-up(md) {
      margin-top: 0;
    }
  }
}

.storeContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint-up(md) {
    justify-content: flex-start;
  }

  img {
    padding: 0 5px;

    @include breakpoint-up(md) {
      padding-left: 0;
      // height: 30;
    }
  }
}

.boxWrapper {
  margin-top: 40px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint-up(md) {
    margin-top: 20px;
  }
}

.boxItem {
  margin: 8px auto;
  padding: 20px 40px;
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-align: center;
  max-width: 250px;

  h3 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.link {
  color: #1c70cf;
  font-size: 18px;
  font-weight: 700;
}
